<template>
  <a href="/">
  <div class="frame-1">
    <a href="/">
    <img
      class="image-6"
      src="https://cdn.animaapp.com/projects/668fabe1a9b7d2ad0686601a/releases/66b60546a796126d7b57a6f8/img/image-6.png"
      alt="image 6"
    />
  </a>
  </div>
</a>

</template>

<script>
export default {
  name: "Frame1",
};
</script>

<style lang="sass">
@import '../../variables'

.frame-1,
.frame-1-1,
.frame-1-2,
.frame-1-3,
.frame-1-4,
.frame-1-5
  align-items: flex-start
  background-color: $white
  box-shadow: 0px 5px 39px #00000014
  display: flex
  height: 138px
  left: 0
  padding: 39.2px 152px
  position: absolute
  top: 0
  width: 1920px

.image-6,
.image-6-1,
.image-6-2,
.image-6-3,
.image-6-4,
.image-6-5
  height: 60px
  margin-left: 1517px
  object-fit: cover
  width: 99px

</style>
