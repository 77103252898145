<template>
  <div :class="[`group-11604694-7`, className || ``]">
    <img
      class="primary-star-tri-color-rgb-1"
      src="https://cdn.animaapp.com/projects/668fabe1a9b7d2ad0686601a/releases/66b60546a796126d7b57a6f8/img/primary-star-tri-color-rgb-1-15.svg"
      alt="Primary Star Tri color-RGB 1"
    />
  </div>
</template>

<script>
export default {
  name: "Group116046944",
  props: ["className"],
};
</script>

<style lang="sass">
@import '../../variables'

.group-11604694-7
  background-color: $caribbean-green
  height: 28.07px
  position: relative
  width: 28.07px

.primary-star-tri-color-rgb-1
  height: 19px
  left: 4px
  position: absolute
  top: 4px
  width: 20px

.group-11604694-7.group-116046948,
.group-11604694-7.group-116046948-1,
.group-11604694-7.group-116046948-2,
.group-11604694-7.group-116046948-3,
.group-11604694-7.group-116046948-4,
.group-11604694-7.group-116046948-5,
.group-11604694-7.group-116046948-6
  background-color: $cloud
  height: 28.07px
  position: relative
  width: 28.07px
</style>
