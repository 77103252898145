<template>
  <div class="radio-button_-selected-1">
    <div class="rectangle-29"></div>
  </div>
</template>

<script>
export default {
  name: "RadioButtonSelected",
};
</script>

<style lang="sass">
@import '../../variables'

.radio-button_-selected-1,
.radio-button_-selected-2
  border: 1.5px solid
  border-color: $tangerine
  border-radius: 18px
  height: 24px
  position: relative
  width: 24px

.rectangle-29,
.rectangle-29-1
  background-color: $tangerine
  border-radius: 18px
  height: 12px
  left: 4px
  position: relative
  top: 4px
  width: 12px
</style>
